exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-carlos-slim-la-nueva-inversion-con-la-que-conquistara-jsx": () => import("./../../../src/pages/carlos-slim-la-nueva-inversión-con-la-que-conquistara.jsx" /* webpackChunkName: "component---src-pages-carlos-slim-la-nueva-inversion-con-la-que-conquistara-jsx" */),
  "component---src-pages-daniela-lujan-cuenta-que-su-primera-decepcion-artistica-fue-culpa-de-belinda-jsx": () => import("./../../../src/pages/daniela-lujan-cuenta-que-su-primera-decepcion-artistica-fue-culpa-de-belinda.jsx" /* webpackChunkName: "component---src-pages-daniela-lujan-cuenta-que-su-primera-decepcion-artistica-fue-culpa-de-belinda-jsx" */),
  "component---src-pages-danna-paola-es-captada-besando-a-su-bailarina-asi-reacciono-alex-hoyer-jsx": () => import("./../../../src/pages/danna-paola-es-captada-besando-a-su-bailarina-asi-reacciono-alex-hoyer.jsx" /* webpackChunkName: "component---src-pages-danna-paola-es-captada-besando-a-su-bailarina-asi-reacciono-alex-hoyer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maluma-enciende-polemica-en-redes-sociales-al-pasarla-mal-en-una-discoteca-jsx": () => import("./../../../src/pages/maluma-enciende-polemica-en-redes-sociales-al-pasarla-mal-en-una-discoteca.jsx" /* webpackChunkName: "component---src-pages-maluma-enciende-polemica-en-redes-sociales-al-pasarla-mal-en-una-discoteca-jsx" */),
  "component---src-pages-mario-vargas-llosa-rompe-el-silencio-sobre-su-ruptura-con-isabel-preysler-jsx": () => import("./../../../src/pages/mario-vargas-llosa-rompe-el-silencio-sobre-su-ruptura-con-isabel-preysler.jsx" /* webpackChunkName: "component---src-pages-mario-vargas-llosa-rompe-el-silencio-sobre-su-ruptura-con-isabel-preysler-jsx" */),
  "component---src-pages-martin-vizcarra-asegura-dejar-la-presidencia-de-peru-con-la-frente-en-alto-jsx": () => import("./../../../src/pages/martin-vizcarra-asegura-dejar-la-presidencia-de-peru-con-la-frente-en-alto.jsx" /* webpackChunkName: "component---src-pages-martin-vizcarra-asegura-dejar-la-presidencia-de-peru-con-la-frente-en-alto-jsx" */),
  "component---src-pages-messi-protagoniza-la-foto-con-mas-likes-en-el-mundo-jsx": () => import("./../../../src/pages/messi-protagoniza-la-foto-con-mas-likes-en-el-mundo.jsx" /* webpackChunkName: "component---src-pages-messi-protagoniza-la-foto-con-mas-likes-en-el-mundo-jsx" */),
  "component---src-pages-mon-laferte-vendio-sustancias-ilicitas-durante-su-etapa-estudiantil-jsx": () => import("./../../../src/pages/mon-laferte-vendio-sustancias-ilicitas-durante-su-etapa-estudiantil.jsx" /* webpackChunkName: "component---src-pages-mon-laferte-vendio-sustancias-ilicitas-durante-su-etapa-estudiantil-jsx" */),
  "component---src-pages-no-es-necesario-cambiar-o-distorsionar-el-vocabulario-con-otras-palabras-adicionales-jsx": () => import("./../../../src/pages/no-es-necesario-cambiar-o-distorsionar-el-vocabulario-con-otras-palabras-adicionales.jsx" /* webpackChunkName: "component---src-pages-no-es-necesario-cambiar-o-distorsionar-el-vocabulario-con-otras-palabras-adicionales-jsx" */),
  "component---src-pages-se-conocieron-detalles-del-enfrentamiento-entre-camila-homs-y-tini-stoessel-jsx": () => import("./../../../src/pages/se-conocieron-detalles-del-enfrentamiento-entre-camila-homs-y-tini-stoessel.jsx" /* webpackChunkName: "component---src-pages-se-conocieron-detalles-del-enfrentamiento-entre-camila-homs-y-tini-stoessel-jsx" */),
  "component---src-pages-shakira-discutio-y-le-grito-a-la-esposa-de-messi-en-una-fiesta-jsx": () => import("./../../../src/pages/shakira-discutio-y-le-grito-a-la-esposa-de-messi-en-una-fiesta.jsx" /* webpackChunkName: "component---src-pages-shakira-discutio-y-le-grito-a-la-esposa-de-messi-en-una-fiesta-jsx" */),
  "component---src-pages-tongo-fue-internado-de-emergencia-en-el-inen-y-su-estado-es-delicado-segun-revela-su-esposa-jsx": () => import("./../../../src/pages/tongo-fue-internado-de-emergencia-en-el-inen-y-su-estado-es-delicado-segun-revela-su-esposa.jsx" /* webpackChunkName: "component---src-pages-tongo-fue-internado-de-emergencia-en-el-inen-y-su-estado-es-delicado-segun-revela-su-esposa-jsx" */)
}

